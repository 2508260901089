<template>
  <div>
    <div v-if="row['editable'] === 'true'">
      <addField v-if="currentTable.id !== 'dataTableexplorers'"
          :path="currentTable.tableUrl"
          :additionalAttributes="{ 'n_id':row['n_hist_id'], 'n_id_key':row['n_hist_id_key'], function:'showRecoveryDialog'}">
        {{ originalValue }}
      </addField>
      <div v-else-if="currentTable.id === 'dataTableexplorers'" class="version-recover-link-clickable">
        <span @click="openDialog">{{ originalValue }}</span>
        <loading-dialog v-model="loading"/>
        <explorer-dialog v-model="dialog"
            :explorerData="explorerData"
            :result="result"
            :tags="currentTable.header.c_tags.associatedOption"
            @reload-table="$emit('reload-table');"
            history/>
      </div>
    </div>
    <span v-else>{{ originalValue }}</span>
  </div>
</template>
<script>
import addField from '@/commonComponents/addField.vue';
import ExplorerDialog from '@/components/specific/ExplorerDialog';
import LoadingDialog from '@/components/basic/LoadingDialog';

export default {
  components: {
    LoadingDialog,
    ExplorerDialog,
    addField,
  },
  props: ['row', 'header', 'currentTable', 'result'],
  data() {
    return {
      dialog: false,
      loading: false,
      explorerData: {},
      dialogResult: {},
    };
  },
  computed: {
    originalValue() {
      return this.row[this.header];
    },
  },
  methods: {
    openDialog() {
      this.loading = true;
      this.frameworkGetRequest('', 'showRecoveryDialog', {
        n_id: this.row.n_hist_id,
        n_id_key: this.row.n_hist_id_key,
      }, (result) => {
        this.loading = false;
        this.dialog = true;
        result.valueLine.n_dataset_version = this.row.n_dataset_version;
        this.explorerData = result.valueLine;
        this.dialogResult = result;
      });
    },
  },
};
</script>

<style scoped>
.version-recover-link-clickable {
  cursor: pointer;
  color: var(--v-anchor-base);
}
</style>